import { useEffect, useRef } from 'react';
import { ApplyForIDO } from '@decub8/ui';
import { motion } from 'framer-motion';
import { GetServerSideProps } from 'next';

import { api_client } from '@src/bootstrap/index';
import { Content, ContentSection } from '@src/components';
import {
    CompletedEventsSection,
    MainEventCardContainer,
    SecondaryEventCardsContainer,
} from '@src/components/HomepageComponents';
import { useLatestEventData } from '@src/components/HomepageComponents/MainEventCardContainer/hooks';
import { UpcomingEventsContainer } from '@src/components/HomepageComponents/UpcomingEventsContainer';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { CONTENT_API } from '@src/services/content';
import { ContentSectionType } from '@src/ts/constants';
import { IContentSection } from '@src/ts/interfaces';
import { getIDOSectionProps } from '@src/utils/getters';

const Home: React.FC<{
    hero_section: IContentSection;
}> = ({ hero_section }) => {
    const events_ref = useRef<HTMLDivElement>(null);

    const {
        projects,
        projects_loaded,
        loadProjects,
        loadContent,
        content_loaded,
        content_sections: {
            partner_section,
            homepage_faqs,
            ido_section,
            get_started_section,
            additional_info_section,
        },
    } = useGlobalContext();

    useEffect(() => {
        loadProjects();
    }, [loadProjects]);

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const { next_project_event, secondary_events } =
        useLatestEventData(projects);

    const id_section_props = getIDOSectionProps(ido_section);

    return (
        <>
            <ContentSection section={hero_section} events_ref={events_ref} />
            <Content padding={false} className="mb-[200px]">
                <div className={`px-5`}>
                    <div ref={events_ref}>
                        {next_project_event && (
                            <motion.section
                                initial={{ height: '0vh', opacity: 0 }}
                                animate={
                                    content_loaded
                                        ? { height: 'auto', opacity: 1 }
                                        : {}
                                }
                                transition={{ duration: 0.2 }}
                            >
                                <MainEventCardContainer
                                    className={'w-full'}
                                    next_project_event={next_project_event}
                                />
                            </motion.section>
                        )}
                        {secondary_events.length > 0 && (
                            <motion.section
                                initial={{ opacity: 0 }}
                                transition={{ duration: 0.4 }}
                                animate={projects_loaded ? { opacity: 1 } : {}}
                            >
                                <SecondaryEventCardsContainer
                                    secondary_events={secondary_events}
                                    className={'mt-8'}
                                />
                            </motion.section>
                        )}
                    </div>
                    {partner_section && (
                        <motion.section
                            initial={{ opacity: 0 }}
                            transition={{ duration: 0.7 }}
                            animate={content_loaded ? { opacity: 1 } : {}}
                        >
                            <ContentSection
                                className={'mt-[40px] md:mt-[120px]'}
                                section={partner_section}
                            />
                        </motion.section>
                    )}

                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                        animate={content_loaded ? { opacity: 1 } : {}}
                    >
                        <UpcomingEventsContainer
                            className={'mt-[40px] md:mt-[120px]'}
                        />
                    </motion.section>

                    <CompletedEventsSection
                        className={'mt-[40px] md:mt-[120px]'}
                    />

                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <ApplyForIDO
                            className={'mt-[40px] md:mt-[120px]'}
                            {...id_section_props}
                        />
                    </motion.section>

                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <ContentSection
                            className={'mt-[40px] md:mt-[120px]'}
                            section={get_started_section}
                            events_ref={events_ref}
                        />
                    </motion.section>

                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <ContentSection
                            section={additional_info_section}
                            className={'mt-[40px] md:mt-[120px]'}
                        />
                    </motion.section>

                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                        animate={content_loaded ? { opacity: 1 } : {}}
                    >
                        <ContentSection
                            className={'mt-[40px] md:mt-[120px]'}
                            section={homepage_faqs}
                        />
                    </motion.section>
                </div>
            </Content>
        </>
    );
};

export const getServerSideProps: GetServerSideProps = async () => {
    try {
        // Fetch all the required data using the unified query
        const { retrieveHeroSection: hero_section } = await api_client.query<{
            retrieveHeroSection: IContentSection;
        }>({
            query: CONTENT_API.RETRIEVE_HERO_SECTION,
            variables: {
                name: ContentSectionType.HeroSection,
            },
            fetchPolicy: 'network-only',
        });

        return {
            props: {
                hero_section,
            },
        };
    } catch (e) {
        console.log(e);
        return {
            props: {
                projects: [],
            },
        };
    }
};

export default Home;
