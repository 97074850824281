import { SortOrder } from '@decub8/ui';
import { commify, formatUnits } from '@ethersproject/units';
import dayjs from 'dayjs';
import { NextRouter } from 'next/router';

import {
    BASE_IMAGE_URL,
    CONTRACT,
    DEFAULT_CHAIN_ID,
    NETWORKS,
} from '@src/config';
import { ICompletedEvent } from '@src/ts/interfaces';
import { bnFormatter, generateSlug, nFormatter } from '@src/utils/format';

export const sortData = (
    data: ICompletedEvent[],
    selected_sort_key: string,
    selected_sort_order: SortOrder,
): ICompletedEvent[] => {
    // Sort the data based on the active sort key
    return [...data].sort((a, b) => {
        const { decimals: a_payment_decimals } =
            CONTRACT.PaymentToken[a.network || DEFAULT_CHAIN_ID];

        const { decimals: b_payment_decimals } =
            CONTRACT.PaymentToken[b.network || DEFAULT_CHAIN_ID];

        let valueA: number;
        let valueB: number;

        // Handle specific fields differently based on expected data types
        switch (selected_sort_key) {
            case 'ended_date':
                valueA = parseInt(a[selected_sort_key] as string, 10); // Parsing timestamp as integer
                valueB = parseInt(b[selected_sort_key] as string, 10);
                break;

            case 'total_raised':
                valueA = parseFloat(
                    formatUnits(
                        a[selected_sort_key] as string,
                        a_payment_decimals,
                    ),
                );
                valueB = parseFloat(
                    formatUnits(
                        b[selected_sort_key] as string,
                        b_payment_decimals,
                    ),
                );
                break;
            case 'total_distributed':
                valueA = parseFloat(
                    formatUnits(
                        a[selected_sort_key] as string,
                        a.token_decimals,
                    ),
                );
                valueB = parseFloat(
                    formatUnits(
                        b[selected_sort_key] as string,
                        b.token_decimals,
                    ),
                );
                break;
            case 'participants':
                {
                    // use the maximum between participants and num_whitelisted

                    const a_participants = a.participants || 0;
                    const a_num_whitelisted = a.num_whitelisted || 0;

                    const b_participants = b.participants || 0;
                    const b_num_whitelisted = b.num_whitelisted || 0;

                    valueA = Math.max(a_participants, a_num_whitelisted);

                    valueB = Math.max(b_participants, b_num_whitelisted);
                }
                break;
            case 'fdv':
            case 'ath_roi':
            case 'ath':
                valueA =
                    typeof a[selected_sort_key] === 'string'
                        ? parseFloat(a[selected_sort_key] as unknown as string)
                        : (a[selected_sort_key] as number);
                valueB =
                    typeof b[selected_sort_key] === 'string'
                        ? parseFloat(b[selected_sort_key] as unknown as string)
                        : (b[selected_sort_key] as number);
                break;
            default:
                valueA =
                    typeof a[selected_sort_key] === 'string'
                        ? parseFloat(a[selected_sort_key] as unknown as string)
                        : (a[selected_sort_key] as number);
                valueB =
                    typeof b[selected_sort_key] === 'string'
                        ? parseFloat(b[selected_sort_key] as unknown as string)
                        : (b[selected_sort_key] as number);
        }

        // Handle NaN values gracefully
        if (isNaN(valueA)) valueA = 0;
        if (isNaN(valueB)) valueB = 0;

        return selected_sort_order === SortOrder.ASC
            ? valueA - valueB
            : valueB - valueA;
    });
};

export const getDataArr = (data: ICompletedEvent[], router: NextRouter) =>
    data?.map(
        ({
            name,
            project_slug,
            fdv,
            network,
            participants,
            num_whitelisted,
            ath_roi,
            ath,
            ticker,
            total_raised,
            ended_date,
            total_distributed,
            token_decimals,
            hardcap = '0',
            project_logo,
            project_id,
        }) => {
            const { symbol: payment_symbol, decimals: payment_decimals } =
                CONTRACT.PaymentToken[network || DEFAULT_CHAIN_ID];

            const event_slug = generateSlug(name);

            // use 1 if hardcap === 0 as 0/0 === NaN
            const percent_filled = (
                (Number(formatUnits(total_raised, payment_decimals)) /
                    Number(formatUnits(hardcap, payment_decimals))) *
                100
            ).toFixed(1);

            const _participants = participants || 0;
            const _num_whitelisted = num_whitelisted || 0;

            // Select the larger value
            const max_participants = Math.max(_participants, _num_whitelisted);

            return {
                name: name,
                logo: `${BASE_IMAGE_URL}/${project_id}/${project_logo}`,
                handleEventClick: () =>
                    router.push(`/project/${project_slug}/${event_slug}`),
                token_symbol: ticker,
                fvd: `${nFormatter(fdv)} USD`,
                athroi: {
                    value:
                        ath_roi > 0
                            ? `+${commify(ath_roi.toFixed(2))}%`
                            : `${commify(ath_roi.toFixed(2))}%`,
                    color: ath_roi > 0 ? 'text-success' : 'text-error',
                },
                ath: {
                    value: commify(ath.toFixed(4)),
                },
                total_raised: {
                    top_value: `${bnFormatter(
                        total_raised,
                        payment_decimals,
                    )} ${payment_symbol}`,
                    bottom_value: percent_filled
                        ? `${percent_filled}% funded`
                        : null,
                },
                participants: commify(max_participants),
                end_date: {
                    top_date: dayjs(Number(ended_date) * 1000).format(
                        'D MMM, YYYY',
                    ),
                    bottom_time: dayjs(Number(ended_date) * 1000).format(
                        'h:mmA',
                    ),
                },
                total_distributed: `${bnFormatter(
                    total_distributed,
                    token_decimals,
                )} ${ticker}`,
                network_logo:
                    NETWORKS[network || DEFAULT_CHAIN_ID].network_logo,
            };
        },
    );
