import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@decub8/ui';

import { CONTENT } from '@src/config';

import { getFillerCard } from './utils';

export const FillerCard: React.FC<{
    has_cut_corners?: boolean;
    className?: string;
}> = ({ className, has_cut_corners }) => {
    const [filler_card_image, setFillerCardImage] = useState<string>('');

    useEffect(() => {
        getFillerCard().then((filler_card_image) => {
            setFillerCardImage(filler_card_image);
        });
    }, []);

    return (
        <div
            className={`low-border rounded-lg p-8 bg-cover bg-center relative flex-grow flex-shrink basis-0 min-w-[334px] max-w-[380px] w-full flex flex-col ${className} ${
                has_cut_corners
                    ? 'cut-corner-card'
                    : 'bg-surface-level-one hover:bg-surface-level-two'
            }`}
            style={
                !filler_card_image
                    ? { backgroundImage: `url(${filler_card_image})` }
                    : undefined
            }
        >
            <div className="flex-grow" />

            <div className="text-center">
                <Typography className="max-w-[228px] mx-auto" size="3xl">
                    New event is coming soon
                </Typography>
                <Button
                    className="w-full mt-10"
                    onClick={() =>
                        window.open(
                            CONTENT.apply_for_ido_link,
                            '_blank',
                            'norefferer',
                        )
                    }
                    variant="secondary"
                >
                    Apply for IDO
                </Button>
            </div>
        </div>
    );
};
