import { MainEventCard } from '@decub8/ui';

import { ProjectContextProvider } from '@src/components/Project/context';
import { Project } from '@src/ts/interfaces';

import { useMainEventCard } from './hooks';

export enum DisplayStatus {
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
}

export const MainEventCardComponent: React.FC<{
    next_project_event: Project;
    className?: string;
}> = ({ next_project_event, className }) => {
    const props = useMainEventCard(next_project_event);

    return (
        <div className={className}>
            <MainEventCard {...props} />
        </div>
    );
};

export const MainEventCardContainer: React.FC<{
    next_project_event: Project;
    className?: string;
}> = ({ next_project_event, className }) => {
    return (
        <ProjectContextProvider
            project={next_project_event}
            event={
                next_project_event.events[0]
                    ? {
                          ...next_project_event.events[0],
                          id: Number(next_project_event.events[0]?.id), // need to id convert to number
                      }
                    : undefined
            }
            children={
                <MainEventCardComponent
                    next_project_event={next_project_event}
                    className={className}
                />
            }
        />
    );
};
